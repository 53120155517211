var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('b-form',[_c('div',[_c('h1',{staticClass:"mr-sm-4 header-tablepage"},[_vm._v("News/Promotion News Detail")])]),_c('div',{staticClass:"title-tabs mt-3"},[_c('div',{staticClass:"content-between"},[_vm._v(" Promotion Info "),_c('InputSelectLanguage',{ref:"inputLang",on:{"changeLang":_vm.changeLanguage}})],1)]),(_vm.isLoading)?_c('div',[_c('OtherLoading')],1):_c('b-container',{staticClass:"no-gutters bg-white"},[_c('div',{staticClass:"pt-3"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('InputText',{attrs:{"textFloat":"Name","placeholder":"Name","type":"text","name":"promotionname","isRequired":"","v":_vm.$v.form.promotion.news_language.$each.$iter[_vm.selectLanguage]
                    .name,"isValidate":_vm.$v.form.promotion.news_language.$each.$iter[_vm.selectLanguage]
                    .name.$error},model:{value:(_vm.form.promotion.news_language[_vm.selectLanguage].name),callback:function ($$v) {_vm.$set(_vm.form.promotion.news_language[_vm.selectLanguage], "name", $$v)},expression:"form.promotion.news_language[selectLanguage].name"}})],1),_c('b-col',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"label"},[_vm._v(" Promotions Type "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])]),_c('InputSelect',{attrs:{"title":"","name":"Promotions Type","isRequired":"","isValidate":_vm.$v.form.promotion.mobile_news_type_id.$error,"options":_vm.promotionList,"valueField":"id","textField":"name","noPleaseSelect":_vm.noPleaseSelect,"disabled":_vm.isEdit,"v":_vm.$v.form.promotion.mobile_news_type_id},on:{"onDataChange":_vm.handleChangeType},model:{value:(_vm.form.promotion.mobile_news_type_id),callback:function ($$v) {_vm.$set(_vm.form.promotion, "mobile_news_type_id", $$v)},expression:"form.promotion.mobile_news_type_id"}})],1)],1),_c('b-row',[_c('b-col',[_c('InputTextArea',{attrs:{"textFloat":"Short Description","placeholder":"Short Description","isRequired":"","value":_vm.form.promotion.news_language[_vm.selectLanguage]
                    .short_description,"v":_vm.$v.form.promotion.news_language.$each.$iter[_vm.selectLanguage]
                    .short_description,"isValidate":_vm.$v.form.promotion.news_language.$each.$iter[_vm.selectLanguage]
                    .short_description.$error,"rows":"3"},model:{value:(
                  _vm.form.promotion.news_language[_vm.selectLanguage]
                    .short_description
                ),callback:function ($$v) {_vm.$set(_vm.form.promotion.news_language[_vm.selectLanguage]
                    , "short_description", $$v)},expression:"\n                  form.promotion.news_language[selectLanguage]\n                    .short_description\n                "}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('UploadFile',{attrs:{"textFloat":"Cover Image","placeholder":"Please Choose File","format":"all","fileName":_vm.form.promotion.image_url,"name":"thumbnail","text":"*Please upload only .png, .jpg image with ratio 1:1","isRequired":"","accept":_vm.acceptType,"v":_vm.$v.form.promotion.image_url,"disabled":_vm.isDisable,"isValidate":_vm.$v.form.promotion.image_url.$error},on:{"onFileChange":_vm.onImageChange},model:{value:(_vm.form.promotion.image_url),callback:function ($$v) {_vm.$set(_vm.form.promotion, "image_url", $$v)},expression:"form.promotion.image_url"}}),_c('PreviewBox',{attrs:{"disabled":_vm.isDisable,"showPreviewType":0,"showPreview":_vm.showPreview,"isLoadingImage":_vm.isLoadingImage,"ratioType":1},on:{"delete":_vm.deleteImage}})],1)],1)],1),_c('b-col',{staticClass:"mt-3 mb-3",attrs:{"md":"12"}},[_c('PreviewMultipleImages',{attrs:{"items":_vm.form,"v":_vm.$v.form.promotion},on:{"deleteImages":_vm.deleteImagesList,"updateImageList":_vm.updateImageList}})],1),_c('b-col',{attrs:{"md":"12"}},[_c('TextEditorsTiny',{attrs:{"textFloat":"Description","rows":4,"value":_vm.form.promotion.news_language[_vm.selectLanguage].description,"name":'description_' + 1,"placeholder":"Type something...","isRequired":"","v":_vm.$v.form.promotion.news_language.$each.$iter[_vm.selectLanguage]
                    .description,"isValidate":_vm.$v.form.promotion.news_language.$each.$iter[_vm.selectLanguage]
                    .description.$error},on:{"onDataChange":_vm.setValue}})],1),_c('b-col')],1)],1)]),_c('div',{staticClass:"no-gutters bg-white mt-3 py-2 px-3"},[_c('b-form-checkbox',{staticClass:"radio-active",attrs:{"switch":"","size":"lg","value":1,"unchecked-value":0},model:{value:(_vm.form.promotion.status),callback:function ($$v) {_vm.$set(_vm.form.promotion, "status", $$v)},expression:"form.promotion.status"}},[_c('span',{staticClass:"ml-2 main-label"},[_vm._v(_vm._s(_vm.form.promotion.status ? "Active" : "Inactive"))])])],1),_c('FooterAction',{attrs:{"routePath":"/promotionnews"},on:{"submit":function($event){return _vm.checkForm(0)}}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }